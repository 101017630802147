@charset "UTF-8";
@import "https://fonts.googleapis.com/css?family=Montserrat:400,700";
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700");
@font-face {
    font-family: 'MontserratLight';
    src: url("../fonts/MontserratLight.eot");
    src: url("../fonts/MontserratLight.eot")
    format("embedded-opentype"),
    url("../fonts/MontserratLight.woff2") format("woff2"),
    url("../fonts/MontserratLight.woff") format("woff"),
    url("../fonts/MontserratLight.ttf") format("truetype"),
    url("../fonts/MontserratLight.svg#MontserratLight") format("svg");
}
body{
    font-family:MontserratLight;
}
.text-white{
    color: #fff;
}
.f-s-20{
    font-size: 20px;
}
.ant-layout-header{
    padding: 0 20px;
}
.min-height-80vh{
    min-height: 100vh;
}
.logo{
    line-height: 60px;
    height: 60px;
    font-size: 20px;
    text-align: center;
    color: #fff;
}
.logo img{
    width: 60%;
    height: 100%;
}
.p-20{
    padding: 20px;
}
.flex-between{
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.login-container{
    height: 100vh;
    display: flex;
    align-items: center;
}
.login-box{
    width: 100%;
}
.login-box h1{
    margin: 50px 0;
    text-align: center;

}
.login-box h2{
    font-weight: 700;
    margin: 50px 0;
    font-size: 24px;
    text-align: center;
}
.login-box h1 img{
    width: 40%;
}
.preview-container{
    box-sizing: border-box;
    padding: 5px;
    max-width: 50%;
    border: 1px solid #e2e2e2;;
    margin-top: 10px;
}
.error-page{
    background-image: url(../img/HTML-404-Page.gif);
    background-size: cover;
    min-height: 100vh;
}
.login-background{
    background-image: url(../img/login-bg.png);
    background-size: cover;
    min-height: 100vh;
}
.logo-container{
    text-align: center;
    margin: 50px 0;
}
.logo-container img{
    width: 30%;
}
.training-paper{
    width: 100%;
    display: flex;
    justify-content: center;
}
.paper-box{
    width: 500px;
    justify-content: space-between;
    display:flex;

}
/* 答题界面 */
.testpanel{
    width: 100%;
    height: 100vh;
    background-color: #f7f9fb;
}
.testpanel-header {
    position: relative;
    width: 100%;
    height: 80px;
    padding:  0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    box-shadow: 1px 1px 3px #f0f4f8;
}

.testpanel-header h1{
    font-size: 30px ;
}

.testpanel-header div {
    position: absolute;
    right: 20px;
    top:27px;
    display: inline-block;
    width: 60px;
    height: 26px;
    text-align: center;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    font-size: larger;
    cursor: pointer;
    background-color: orange;
}

.switch-btn button {
    border: none;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 4px;
    cursor: pointer;
    border-radius: 4px;
}
 .test-container{
     min-height: 80vh;
     display: flex;
     align-items: center;
 }
 .testpanel-main{
     border-radius: 6px;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     width: 100%;
     height: 80vh;
     justify-self: flex-end;
     padding: 50px;
     margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1)
 }
 .testpanel-main>h2{
     font-size: 25px;
     font-weight: 700;
 }
 .testpanel-main h2{
    margin-bottom: 20px;
 }
 .testpanel-nav{
     height: 80vh;
     width: 100%;
     padding: 20px;
     margin-top: 20px;
 }
 .testpanel-nav>.nav-title{
     font-size: 20px;
     margin-bottom: 20px;
     font-weight: 700;
 }
 .testpanel-ul{
     display: flex;
     flex-wrap: wrap;
 }
 .testpanel-ul li{
     cursor: pointer;
     margin-right: 10px;
     margin-bottom: 10px;
     width: 40px;
     height: 40px;
     line-height: 40px;
     text-align: center;
     align-items: center;
     border-radius: 2px;
     border: 1px solid #e2e2e2;
     box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
 }
 .testpanel-ul li.active{
     background-color: #40a9ff!important;
     color: #fff;
 }
 .testpanel-ul li.is-done{
     color: #fff;
     background-color: #666;
 }
 .question-switch{
    display: flex;
 }
 .question-switch .switch-btn{
     cursor: pointer;
     height: 40px;
     padding: 0 40px;
     line-height: 40px;
     font-size: 20px;
     box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     border-radius: 4px;
 }
 .question-option{
     gap: 8px;
     display: flex;
     flex: 0 50%;
     margin-bottom: 10px;
 }
 .question-option .ant-form-item{
     margin-bottom: 10px;
 }
 .question-option span.ant-radio + *{
     font-size: 16px;
 }
.login-box .ant-btn{
     border-radius: 5px;
     height: 42px;
     line-height: 42px;
     padding: 0;
 }
.login-box .ant-btn>span{
     vertical-align: middle;
 }
.login-box .ant-input,.ant-input-password{
    border-radius: 5px;
 }
 .ant-btn{
    border-radius: 5px;
 }
 .questions{
     display: flex;
     justify-content: space-between;
     align-items: center;
 }
 .ant-transfer{
     justify-content: center;
 }
 .table-top{
     display: flex;
     justify-content: flex-end;
 }
 .question-media{
     overflow: hidden;
     flex: 1;
 }
 .question-media img{
     display: block;
     height: 100%;
 }
 .question-option .ant-checkbox-group-item{
    display: flex;
    margin: 30px 0;
 }
 .flex-1{
     flex: 1;
 }
 .min-layout-height{
     min-height: 80vh;
 }
 .finish-result .ant-result-icon span{
    width:92px;
    height:92px;
 }
 .finish-result .ant-result-icon svg{
    width:92px;
    height:92px;
 }
 .finish-result .ant-result-title{
    font-size:32px;
 }
 .finish-result .ant-result-subtitle{
    font-size:42px;
 }
 .finish-result .ant-result-extra button{
    font-size:32px;
    height:60px;
 }
 .courseware-container{
    border:1px solid #d9d9d9;
    border-radius: 2px;
    padding: 4px;
 }
.courseware-container ul{
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    gap: 5px;
}
.courseware-container ul li:hover .courseware-delete-icon{
    display: block;
}
.courseware-container ul li {
    overflow: hidden;
    box-sizing: border-box;
    padding: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.courseware-container ul li i{
    overflow: hidden;
    position: relative;
    display: block;
    height: 100%;
}
.courseware-box ul{
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(3, 150px);
    gap: 5px;
}
.courseware-box ul li {
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.courseware-box ul li i{
    overflow: hidden;
    position: relative;
    display: block;
    height: 100%;
}
.courseware-box img,.courseware-box video{
    object-fit: cover;
    object-position: center center;
    width:100%;
    height: 100%;
    display: block;
}
.courseware-container img,.courseware-container video{
    object-fit: cover;
    object-position: center center;
    width:100%;
    height: 100%;
    display: block;
}
.courseware-delete-icon{
    z-index: 999;
    display: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    color: #ff4d4f;
    font-size: 25px;
}
.courseware-modal ul{
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(3, 150px);
    gap: 5px;
}
.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.dropzone:focus {
    border-color: #2196f3;
}

.dropzone.disabled {
    opacity: 0.6;
}
.video-react.video-react-fluid{
    height: 100%!important;
}
.ant-image-preview-switch-right{
    right: 100px;
}
.ant-image-preview-switch-left{
    left: 100px;
}
.ant-image-preview-switch-left, .ant-image-preview-switch-right{
    width: 80px;
    height:80px;
}
.ant-image-preview-switch-left > .anticon, .ant-image-preview-switch-right > .anticon{
    font-size: 30px;
}